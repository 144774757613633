@import "../../content/css/config.scss";

$default-padding: 0.8em 1.4em;
$default-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

.field {
  flex: 1;
  margin: 1rem 0rem 1.2rem 0rem;

  .MuiTypography-subtitle2 {
    margin-bottom: 0.4rem;
  }
}

.plate-toolbar-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

}

.atticus-editor-container {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  padding-left: 1em;

  .att-editor-bar {
    width: 100%;
    position: sticky;
    align-items: center;
    top: 0;
    background-color: $default-bg-color;
    z-index: 11;
    margin-top: 1em;
  }
  .att-editor-title {
    font-size: 1.375em;
    font-weight: 600;
    padding: 0 15px;
    line-height: 1;
  }
  .chapter-type-toolbar {
    margin-bottom: 0;
    padding-left: 1em;
    padding-bottom: .5em;      
    text-transform: capitalize;
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1;
    color: rgba(0, 0, 0, 0.5);
  }
  .full-page-image-titlebar {
    margin-left: -1em;
    margin-right: -1em;
  }
  .att-editor-subtitle {
    font-size: 1em;
    padding: 0 15px;
  }
  .editor-header {
    min-height: 50px;
    margin-top: 0.5em;
    align-items: center;
    justify-content: space-between;
    padding-right: 1em;
  }
  .editor-col {
    flex: 1;
    display: flex;
    overflow-y: auto;    
  }
  .editor-area {
    background-color: $default-bg-color;
    border-radius: 5px;
    box-shadow: $default-box-shadow;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    flex: 3;
    margin-right: 1em;
    position: relative;

    .chapter-template-link-wrapper{
      padding-left: 4px;
      padding-right: 4px;
      position: absolute;
      padding-bottom: 80px;
      
    }

    .chapter-template-link {
      font-size: 0.85em;
      font-weight: 400;
      padding-left: 16px;
    }

    .chapter-heading-input {
      font-size: 1.6rem;
      font-weight: 600;
      border: 0px;
      padding: 0.4em 0em;
      width: 100%;
      margin: 0px;
      background: none;

      &:focus,
      &:active {
        outline: 0px;
      }
    }

    .editor-textarea {
      width: 100%;
      padding: 1em;
      overflow-x: hidden;
    }

    .editor-loading-textarea {
      width: 100%;
      padding: 1em;
      opacity: 0;
    }
  }
  .editor-spinner-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
    width: 100%;
    top: 50%;
    z-index: 10;
  }
  
  .sync-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sync-text {
    margin-top: 6px;
    font-size: calc(1.5em - 4px);
    font-weight: bold;
    color: $slick-bg-btn-color;
    text-align: center;
  }

  .sync-container img {
    width: 96px;
    height: auto; 
  }

  .masterpage-bottombar-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    position: relative;
    background-color: #F9F9F9;
    padding: 10px 16px;
    border-radius: 5px;
    box-shadow: $default-box-shadow;
  }
  .toolbar_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .plate-toolbar-overrides {
    border: none;
    margin: 0 -18px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;

    .at-toolbar-row {
      width: 100%;
      overflow-x: auto;
    }
  }

  .toolbar {
    max-height: 40px;
    width: 100%;
    min-width: 510px;
    background-color: $default-bg-color;
    border-radius: 5px;
    height: 32px;
    box-shadow: $default-box-shadow;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 0.5em;
    padding-left: 0.5em;

    .divider {
      height: 32px;
    }

    .slate-ToolbarButton {
      width: 24px;
      margin: 0 4px;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .toolbar-align-div {
      margin-left: 5px;
    }

    .quote-dropdown,
    .scene-dropdown,
    .scene-row,
    .quote-row {
      display: flex;
      transition: width 0.2s ease-out; 
    }

    .quote-dropdown,
    .scene-dropdown {
        width: 0;
        overflow: hidden;
    }

    .scene-dropdown-rhs,
    .quote-dropdown-rhs {
      width: 50px;
    }

    @media (max-width: 875px) {
        .quote-dropdown,
        .scene-dropdown {
            width: 50px;
        }
        .scene-dropdown-rhs,
        .quote-dropdown-rhs,
        .quote-row,
        .scene-row {
            width: 0;
            overflow: hidden;
        }
    }

    @media (max-width: 920px) {
        .quote-dropdown {
            width: 50px; 
        }

        .quote-dropdown-rhs,
        .quote-row {
            width: 0;
            overflow: hidden;
        }
    }
  }
  
  .end-notes-toolbar {
    max-height: 40px;
    width: 100%;
    background-color: $default-bg-color;
    border-radius: 5px;
    height: 32px;
    box-shadow: $default-box-shadow;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border: 1px solid #DBDCDD;

    .divider {
      height: 32px;
    }

    .slate-ToolbarButton {
      width: 24px;
      margin: 0 4px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.endnotes-editor-container {
  overflow-y: auto;
  display: flex !important;
  flex-direction: column;
  height: 100%;

  .editor-header {
    min-height: 50px;
    margin-top: 0.5em;
    align-items: center;
    justify-content: space-between;
    padding-right: 1em;
  }
  .editor-col {
    flex: 1;
    display: flex;
    overflow-y: auto;    
  }
  .editor-area {
    background-color: $default-bg-color;
    border-radius: 5px;
    box-shadow: $default-box-shadow;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    flex: 3;
    margin-right: 1em;

    .chapter-type-toolbar {
      margin-bottom: 0;
      padding-left: 1em;
      padding-bottom: .5em;      
      text-transform: capitalize;
      font-size: 0.85em;
      font-weight: 400;
      line-height: 1;
      color: rgba(0, 0, 0, 0.5);
    }

    .chapter-template-link-wrapper{
      padding-left: 4px;
      padding-right: 4px;
      position: absolute;
      padding-bottom: 80px;
      
    }

    .chapter-template-link {
      font-size: 0.85em;
      font-weight: 400;
      padding-left: 16px;
    }

    .chapter-heading-input {
      font-size: 1.6rem;
      font-weight: 600;
      border: 0px;
      padding: 0.4em 0em;
      width: 100%;
      margin: 0px;
      background: none;

      &:focus,
      &:active {
        outline: 0px;
      }
    }

    .editor-textarea {
      width: 100%;
      padding: 1em;
      overflow-x: hidden;
    }
  }
  .editor-area-end-note {
    background-color: $default-bg-color;
    border-radius: 5px;
    box-shadow: $default-box-shadow;
    border:0.5px solid #8cbcf4;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 3;
    max-height: 60vh;

    .editor-textarea {
      width: 100%;
      padding: 1em;
      overflow-x: none;      
    }
  }
  .editor-area-end-note {
    background-color: $default-bg-color;
    border-radius: 5px;
    box-shadow: $default-box-shadow;
    border:0.5px solid #8cbcf4;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    flex: 3;
    height: 180px;

    .editor-textarea {
      width: 95%;
      padding: 1em;
      overflow-x: none;      
    }
  }
  .toolbar_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .plate-toolbar-overrides {
    border: none;
    margin: 0 -18px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;

    .at-toolbar-row {
      width: 100%;
      overflow-x: auto;
    }
  }

  .toolbar {
    max-height: 40px;
    width: 100%;
    min-width: 510px;
    background-color: $default-bg-color;
    border-radius: 5px;
    height: 32px;
    box-shadow: $default-box-shadow;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 0.5em;
    padding-left: 0.5em;

    .divider {
      height: 32px;
    }

    .slate-ToolbarButton {
      width: 24px;
      margin: 0 4px;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .toolbar-align-div {
      margin-left: 5px;
    }

    .quote-dropdown,
    .scene-dropdown,
    .scene-row,
    .quote-row {
      display: flex;
      transition: width 0.2s ease-out; 
    }

    .quote-dropdown,
    .scene-dropdown {
        width: 0;
        overflow: hidden;
    }

    .scene-dropdown-rhs,
    .quote-dropdown-rhs {
      width: 50px;
    }

    @media (max-width: 875px) {
        .quote-dropdown,
        .scene-dropdown {
            width: 50px;
        }
        .scene-dropdown-rhs,
        .quote-dropdown-rhs,
        .quote-row,
        .scene-row {
            width: 0;
            overflow: hidden;
        }
    }

    @media (max-width: 920px) {
        .quote-dropdown {
            width: 50px; 
        }

        .quote-dropdown-rhs,
        .quote-row {
            width: 0;
            overflow: hidden;
        }
    }
  }
  
  .end-notes-toolbar {
    max-height: 40px;
    width: 100%;
    background-color: $default-bg-color;
    border-radius: 5px;
    height: 32px;
    box-shadow: $default-box-shadow;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border: 1px solid #DBDCDD;

    .divider {
      height: 32px;
    }

    .slate-ToolbarButton {
      width: 24px;
      margin: 0 4px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.editor-settings-toolbar-container {
  display: flex !important;
  flex: 1 !important;
  justify-content: flex-end !important;
  height: 40px;
}

.toolbar-button-disable {
  pointer-events: none;
  opacity: 0.2;
}
.tlb-dropdown, .heading-type-dropdown {
  .slate-ToolbarButton {
    width: auto!important;
  }
}
.tlb-monospace {
  font-family: "Courier Prime", monospace;
}
.tlb-smallcaps {
  font-variant: small-caps;
}
.tlb-sansserif {
  font-family: "PT Sans";
}
.tlb-strikethrough {
  text-decoration: line-through;
}
.slate-ToolbarButton-active {
  color: $slick-bg-btn-color;

  .at-tick-square{
    display: flex;
  }
}

.slate-ToolbarButton:hover {
  background-color: #E8E8E9;
  color: #171d21 !important;
  border-radius: 4px; 
}

.modal-confirm {
  .ant-modal-confirm-btns {
    margin-top: 0 !important;

    .ant-btn {
      flex: 1;
    }
  }

  .ant-modal-confirm-content {
    margin: 2em 0 !important;
    ul {
      margin: 0;
    }
  }
}

// Ornamental Break Plugin

.slate-ornamental-break {
  &.selected {
    outline: 2px solid #2da6ea;
  }
  border-radius: 4px;
  margin: 0.4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid whitesmoke;
}

.slate-page-break {
  &.selected {
    outline: 2px solid #2da6ea;
  }
  border-radius: 4px;
  margin: 0.4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid whitesmoke;
}

//Image Plugin
.image-upload-area {
  min-height: inherit !important;

  .MuiDropzoneArea-textContainer {
    padding-bottom: 0.8rem !important;
  }

  .MuiTypography-h5 {
    font-size: 1rem !important;
  }
  .MuiDropzoneArea-text {
    margin-bottom: 12px !important;
  }
}
.slate-image {
  padding-left: 1rem;
}

.slate-endnote {
  display: inline-block;
  vertical-align: middle;
  background-color: $primary-background;
  border-radius: 8px;
  margin-left: 0.2em;
  margin-right: 0.1em;
  padding-right: 0.4em;
  padding-left: 0.4em;
  cursor: pointer;
  border: 1px solid rgba(208, 219, 232, 0.4);
  opacity: 0.8;
}

.edt-img {
  position: relative;
  margin: 1em;

  .edt-img-action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .imge {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      max-width: 420px;
    }

    .caption {
      max-width: 420px;
      width: 100%;
      flex: 1;
      border-left: 4px solid #e8e8e8;
      margin: 0.8rem 0rem;
      padding-left: 1em;
      font-size: 1rem;
    }
  }
}

.image-link-value-container {
  margin-top: 0.7rem;
}

.flow-control-container {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0 1rem;

  .flow-radio-button-container {
  }
  .wrap-check-box-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

.error {
  color:#f00909;
}

.imgUploadTitle {
	text-align: center;
	margin: 35px 0 5px 0;
}

.fileUploadRemoveImg {
  color: #FB4C50;
  text-align: center;
}

.fileUploadImgPrevMainDiv {
  margin: 5px auto 25px auto;
}

.fileUploadImgPre {
  display: flex;
  justify-content: center;
}

.fileUploadRemoveBtn {
  width: 50% !important;
  margin: 5px;
}



.editor-block-cont {
  position: relative;

  .editor-block-action {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 0;
    z-index: 10;

    .editor-block-action-btn {
      height: 2em;
      width: 2em;
      padding: 0!important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.slate-blockquote {
  background-color: whitesmoke;
  padding: 1em 3em 1em 1em;
  border-left: 5px solid rgb(227, 227, 227);

  .slate-blockquote-quotee {
    width: 100%;
    text-align: right;
    color: gray;
  }
}

.element-h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
}

.element-h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;

}

.element-h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
}

.element-h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
}

.element-h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
}

.slate-h5 {
  font-size: .83em !important;
}

.slate-h6 {
  font-size: .67em !important;
}

.dropdown-text {
  white-space: nowrap;
}

//Callout-box plugin styles
.editor-callout-box{
  margin-top: 5px;
}

.callout-unfilled {
  background-color: transparent !important;
}

.callout-no-border {
  border: none !important;
}

//calloutbox properties
.select-dropdown .ant-select-selector{
  border-radius: 4px !important;
  .select-option {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }
}

.inputSpinner {
  // margin: 0 16px;
  margin-left: 16px;
}

.border-weight-row{
  margin-top: 12px;
}
.border-radius-row{
  margin-top: 5px;
}

.writing-section {
  display: flex;
}

.extra-content {
  flex: 1 1 auto;
  width: 10px;
}